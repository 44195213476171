import { CardContent } from '@material-ui/core';
import googleMap from 'components/home-page/main-info-section/google-map';
import Parking from 'components/home-page/main-info-section/parking';
import Payments from 'components/home-page/main-info-section/payments';
import Webcam from 'components/home-page/main-info-section/webcam';
import WorkingHours from 'components/home-page/main-info-section/working-hours';
import { PageContent } from 'components/reservations/shared';
import Card from 'components/shared/card';
import Contacts from 'components/shared/contacts';
import Hat from 'components/shared/hat';
import Img from 'components/shared/img';
import Layout from 'components/shared/layout';
import SectionContainer from 'components/shared/section-container';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import { MD, XS } from 'src/shared/media-query';
import theme from 'src/shared/theme';
import styled, { css } from 'styled-components';

interface Props {
  className?: string;
}

const Cards = styled.section`
  max-width: ${theme.breakpoints.values.lg}px;
  display: grid;
  grid-gap: ${theme.spacing(7)};
  align-items: stretch;
  margin: 0 auto;

  grid-template:
    'info'
    'map'
    'working-hours'
    'webcam'
    'office-photo'
    'parking'
    'credit-card-support';

  @media ${XS} {
    grid-template:
      'info             map'
      'working-hours    webcam'
      'working-hours    webcam'
      'working-hours    webcam'
      'office-photo      parking'
      'office-photo      credit-card-support';
  }

  @media ${MD} {
    grid-template:
      'info                   map                              map' auto
      'working-hours          webcam                   office-photo' auto
      'working-hours          webcam                       parking' 127px
      'working-hours          webcam           credit-card-support' 127px
      /auto auto auto;
  }
`;

const InfoCard = styled(Card)`
  grid-area: info;
  background-color: ${theme.palette.primary.dark};
  color: ${theme.palette.common.white};
`;

const MapCard = styled(googleMap)`
  grid-area: map;
`;

const WorkingHoursCard = styled(WorkingHours)`
  grid-area: working-hours;
`;

const WebcamCard = styled(Webcam)`
  grid-area: webcam;
`;

const OfficePhotoCard = styled(Card)`
  grid-area: office-photo;
`;

const ParkingCard = styled(Parking)`
  grid-area: parking;
`;

const CreditCardSupportCard = styled(Payments)`
  grid-area: credit-card-support;
`;

const ContactsPage: FC<Props> = () => {
  const data = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { id: { eq: "contacts" } }) {
          frontmatter {
            workingHoursCaption
            workingHoursValues {
              days
              time
            }
            emergencyHoursCaption
            emergencyDescription
            emergencyPhoneCaption
            emergencyPhoneNumber
            phones
            email
            webcamTitle
            webcamNote
            parkingTitle
            parkingNote
            paymentsTitle
            officePhoto {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );

  const {
    emergencyHoursCaption,
    emergencyDescription,
    emergencyPhoneCaption,
    emergencyPhoneNumber,
    webcamTitle,
    webcamNote,
    parkingTitle,
    parkingNote,
    paymentsTitle,
    officePhoto: {
      childImageSharp: { fluid: officeImage },
    },
  } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Hat pageName="Kontakty" />
      <div>
        <SectionContainer>
          <PageContent>
            <Cards>
              <InfoCard>
                <CardContent
                  css={css`
                    padding-bottom: 0 !important;
                  `}
                >
                  <Contacts titleVariant="h4" shrinkIcons />
                </CardContent>
              </InfoCard>
              <MapCard />
              <WorkingHoursCard
                emergencyDescription={emergencyDescription}
                emergencyHoursCaption={emergencyHoursCaption}
                emergencyPhoneCaption={emergencyPhoneCaption}
                emergencyPhoneNumber={emergencyPhoneNumber}
              />
              <WebcamCard
                webcamNote={webcamNote}
                webcamTitle={webcamTitle}
                css={css`
                  background: ${theme.palette.primary.lightest};
                `}
              />
              <OfficePhotoCard>
                <Img
                  src={officeImage}
                  title="Příjezd k naší ordinaci"
                  alt="Budova s logem naší ordinace z pohledu od příjezdu"
                  imgStyle={{
                    objectPosition: `top center`,
                    objectFit: `cover`,
                  }}
                  css={css`
                    height: 100%;
                  `}
                />
              </OfficePhotoCard>
              <ParkingCard
                parkingNote={parkingNote}
                parkingTitle={parkingTitle}
                css={css`
                  background: ${theme.palette.primary.lightest};
                `}
              />
              <CreditCardSupportCard
                paymentsTitle={paymentsTitle}
                css={css`
                  background: ${theme.palette.primary.lightest};
                `}
              />
            </Cards>
          </PageContent>
        </SectionContainer>
      </div>
    </Layout>
  );
};

export default ContactsPage;
